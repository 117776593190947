/* Utilizo este fromato de exportación ya que puedo incluir imagenes  */
// puedo incluir un "descrip2" si es necesario

import wpquick from '../../img/01.jpg'
import clontagram from '../../img/02.jpg'
import tickets from '../../img/03.jpg'
import fci from '../../img/04.jpg'
import journal from '../../img/05.jpg'
import calendar from '../../img/06.jpg'
import wpavanzado from '../../img/07.jpg'
import appmaps from '../../img/08.png'
import story from '../../img/09.png'
import fitool from '../../img/10.png'

export const jobsEn = [
    {
        "img": fitool,
        "title": "FiTool",
        "descrip1": "App for personal trainers, allows you to create and manage training routines for your athletes easily and accessible from anywhere.",
        "tagsTec": "react, node, html, css, bootstrap",
        "urlProyecto": "https://fitool.app"
    },
    {
        "img": clontagram,
        "title": "Clontagram",
        "descrip1": "Instagram clone (Web version), App developed for a course.",
        "tagsTec": "reactJS, css, html, js, mongo, node, AWS",
        "urlProyecto": "https://clontagram-course.herokuapp.com/",
        "urlRepo": "https://github.com/NorbertOSK/react-clontagram"
    },
    {
        "img": wpquick,
        "title": "WpQuick",
        "descrip1": "App to send WhatsApp messages without having to save a contact, uses the WhatsApp API, a multi language app.",
        "tagsTec": "reactJS, html, boostrap, PWA, API",
        "urlProyecto": "https://wpquick.demosweb.net/",
        "urlRepo": "https://github.com/NorbertOSK/react-wpquick"
    },
    {
        "img": tickets,
        "title": "Shifts / Tickets",
        "descrip1": "Tickets App, created with HTML / CSS and Socket, works in real time, for this example with a local database.",
        "tagsTec": "html, js, sockets",
        "urlProyecto": "https://nk-sockets-tickets-demo.herokuapp.com/"
    },
    {
        "img": fci,
        "title": "FCI",
        "descrip1": "App developed to measure, to manage a FCI, Client / Server APP. Full backend with node and mongoDB.",
        "tagsTec": "js, TailwindCSS, reactJS, html, node, mongodb, PWA, Chart.js",
        "urlProyecto": "https://inv.fcinok.com/"
    },
    {
        "img": journal,
        "title": "Journal",
        "descrip1": "App that allows you to save notes by attaching an image that is hosted on Cloudinary.",
        "tagsTec": "reactJS, js, boostrap, Firebase Store, API, redux, Sass",
        "urlProyecto": "https://journal.demosweb.net",
        "urlRepo": "https://github.com/NorbertOSK/journal-app"
    },
    {
        "img": calendar,
        "title": "Calendar Events",
        "descrip1": "Events Calendar, different people can create events and view other users' events.",
        "tagsTec": "reactJS, html, js, css, node, mongodb, redux",
        "urlProyecto": "https://reactalendarbackend.herokuapp.com",
        "urlRepo": "https://github.com/NorbertOSK/calendar-app"
    },
    {
        "img": appmaps,
        "title": "App Maps React Mapbox",
        "descrip1": "React with TypesCript, Mapbox API, state handling with Context Provider and Reducer, using the Redux design pattern.",
        "tagsTec": "reactJS, html, tipescript, css, apicontext, reducer, mapbox, axios",
        "urlProyecto": "https://app-maps.demosweb.net/",
        "urlRepo": "https://github.com/NorbertOSK/app-maps"
    },
    {
        "img": story,
        "title": "StoryBook",
        "descrip1": "React with TypesCript, migrating components and building a storybook.",
        "tagsTec": "reactJS, html, tipescript, css",
        "urlProyecto": "https://norbertosk.github.io/sb-components/?path=/docs/example-button--secondary",
        "urlRepo": "https://github.com/NorbertOSK/sb-components",
        "urlNpm": "https://www.npmjs.com/package/nk-my-storybooks-components"
    },
    {
        "img": wpavanzado,
        "title": "WordPress Avanzado",
        "descrip1": "Site created for the project of the same name, offering service for marketing companies and other developers.",
        "tagsTec": "php, mysql, linux",
        "urlProyecto": "https://wordpressavanzado.com"
    }
]