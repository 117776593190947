import React from 'react'
import { AppRouter } from './router/AppRouter'

export const HomeApp = () => {
    return (
        <>     
        <AppRouter />
        </>
    )
}
