/* Utilizo este fromato de exportación ya que puedo incluir imagenes  */
// puedo incluir un "descrip2" si es necesario

import wpquick from '../../img/01.jpg'
import clontagram from '../../img/02.jpg'
import tickets from '../../img/03.jpg'
import fci from '../../img/04.jpg'
import journal from '../../img/05.jpg'
import calendar from '../../img/06.jpg'
import wpavanzado from '../../img/07.jpg'
import appmaps from '../../img/08.png'
import story from '../../img/09.png'
import fitool from '../../img/10.png'

export const jobsEs = [
    {
        "img": fitool,
        "title": "FiTool",
        "descrip1": "App para entrenadores personales, permite crear y gestionar rutinas de entrenamiento para sus atletas de manera fácil y accesible desde cualquier lugar.",
        "tagsTec": "react, node, html, css, bootstrap",
        "urlProyecto": "https://fitool.app"
    },
    {
        "img": clontagram,
        "title": "Clontagram",
        "descrip1": "Clon de Instagram (Versión web), App desarrollada para un curso.",
        "tagsTec": "reactJS, css, html, js, mongo, node, AWS",
        "urlProyecto": "https://clontagram-course.herokuapp.com/",
        "urlRepo": "https://github.com/NorbertOSK/react-clontagram"
    },
    {
        "img": wpquick,
        "title": "WpQuick",
        "descrip1": "App para enviar mensajes de WhastApp sin necesidad de agendar un contacto, utiliza la API de WhastApp, app multilenguaje.",
        "tagsTec": "reactJS, html, boostrap, PWA, API",
        "urlProyecto": "https://wpquick.demosweb.net/",
        "urlRepo": "https://github.com/NorbertOSK/react-wpquick"
    },
    {
        "img": tickets,
        "title": "Sistemas de Tickes/Turnos",
        "descrip1": "App de Turnos/Tickets, creada con HTML/CSS y Socket, funciona en tiempo real, para este ejemplo con una base local.",
        "tagsTec": "html, js, sockets",
        "urlProyecto": "https://nk-sockets-tickets-demo.herokuapp.com/"
    },
    {
        "img": fci,
        "title": "FCI",
        "descrip1": "App desarrollada a medida, para administrar un FCI, APP Cliente/Servidor. Backend completo con node y mongoDB.",
        "tagsTec": "js, TailwindCSS, reactJS, html, node, mongodb, PWA, Chart.js",
        "urlProyecto": "https://inv.fcinok.com/"
    },
    {
        "img": journal,
        "title": "Journal",
        "descrip1": "App que permite guardar notas adjuntando una imagen que se aloja en Cloudinary.",
        "tagsTec": "reactJS, js, boostrap, Firebase Store, API, redux, Sass",
        "urlProyecto": "https://journal.demosweb.net",
        "urlRepo": "https://github.com/NorbertOSK/journal-app"
    },
    {
        "img": calendar,
        "title": "Calendario de Eventos",
        "descrip1": "Calendario de Eventos, diferentes personas pueden crear eventos y ver eventos de otros usuarios.",
        "tagsTec": "reactJS, html, js, css, node, mongodb, redux",
        "urlProyecto": "https://reactalendarbackend.herokuapp.com",
        "urlRepo": "https://github.com/NorbertOSK/calendar-app"
    },
    {
        "img": appmaps,
        "title": "App Mapas React mapbox",
        "descrip1": "React con TypesCript, API Mapbox, manejo de estado con Context Proveider y Reducer, utilizando el patrón de diseño de Redux.",
        "tagsTec": "reactJS, html, tipescript, css, apicontext, reducer, mapbox, axios",
        "urlProyecto": "https://app-maps.demosweb.net/",
        "urlRepo": "https://github.com/NorbertOSK/app-maps"
    },
    {
        "img": story,
        "title": "StoryBook",
        "descrip1": "React con TypesCript, migrando componentes y armando un storybook.",
        "tagsTec": "reactJS, html, tipescript, css",
        "urlProyecto": "https://norbertosk.github.io/sb-components/?path=/docs/example-button--secondary",
        "urlRepo": "https://github.com/NorbertOSK/sb-components",
        "urlNpm": "https://www.npmjs.com/package/nk-my-storybooks-components"
    },
    {
        "img": wpavanzado,
        "title": "WordPress Avanzado",
        "descrip1": "Sitio creado para el proyecto del mismo nombre, ofreciendo servicio para empresas de marketing y otros desarrolladores.",
        "tagsTec": "php, mysql, linux",
        "urlProyecto": "https://wordpressavanzado.com"
    }
]