import React from 'react'

export const Icon = ({ colorActive, colorHover, icon, name }) => {
    return (
        <button className={`flex flex-col ${colorActive} hover:${colorHover} w-20 h-20 shadow-md focus:outline-none rounded-lg text-white text-xs self-center m-2 py-1 cursor-default`}>
            <i className={`${icon} text-5xl self-center fill-current text-white m-1`} aria-hidden="true"></i>
            <p className="self-center">{name}</p>
        </button>
    )
}
