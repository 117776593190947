import React, {useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect  
} from "react-router-dom";
import { HomeScreen } from '../components/home/HomeScreen';
import { JobsScreen } from '../components/jobs/JobsScreen';
import { Navbar } from '../components/ui/Navbar';

import es from '../lang/es.json'
import en from '../lang/en.json'
import { BlogScreen } from '../components/blog/BlogScreen';


export const AppRouter = () => {

  let idioma = localStorage.getItem('lang')
  //console.log(idioma)

  const [lang, setLang] = useState(idioma === 'en' ? en : es)
  //console.log(lang)
  

  function changeLanguaje(){
    if(lang === es){
      setLang(en)
      localStorage.setItem('lang', 'en')
      //console.log('ingles')
    }else if(lang === en){
      setLang(es)
      localStorage.setItem('lang', 'es')
      //console.log('español')
    }
  }


    return (
        <Router>
      <>
      <Navbar changeLang={changeLanguaje} lang={lang}/>
        <Switch>
          <Route exac path="/jobs" component={() => <JobsScreen lang={lang} />} />
          <Route exac path="/blog" component={() => <BlogScreen lang={lang} />} /> {/* reemplazar por compoennte de blog! */}
          <Route exac path="/" component={() => <HomeScreen lang={lang} />} />

          <Redirect to="/" />
        </Switch>
      </>
    </Router>
    )
}
