import React from 'react'

import { Work } from './Work'

import { jobsEs } from './jobs_es'
import { jobsEn } from './jobs_en'

export const JobsScreen = ({ lang }) => {


    //console.log(jobsEsTwo)

    return (
        <>
            <div className="max-w-3xl mx-auto">
                <div className="mx-auto container pt-12 pb-10 px-4 sm:px-4">
                    <h1 className="mb-2 mt-2 text-2xl font-bold">{lang.textJobs.h1}</h1>
                    <main>                    {
                        lang.locale === 'es' &&

                        jobsEs.map((job, index) =>
                            <Work key={index} img={job.img} title={job.title} descrip1={job.descrip1} urlNpm={job.urlNpm} urlProyecto={job.urlProyecto} urlRepo={job.urlRepo} tagsTec={job.tagsTec} lang={lang} />
                        )
                    }{

                            lang.locale === 'en' &&
                            jobsEn.map((job, index) =>
                                <Work key={index} img={job.img} title={job.title} descrip1={job.descrip1} urlNpm={job.urlNpm} urlProyecto={job.urlProyecto} urlRepo={job.urlRepo} tagsTec={job.tagsTec} lang={lang} />
                            )

                        }


                    </main>
                </div>
            </div>
        </>
    )
}
