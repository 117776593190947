import React, { useEffect, useState } from 'react'
import './blog.css'
import { Entradas } from './Entradas'

export const BlogScreen = ({ lang }) => {


    const [post, setPost] = useState([])
    const [loading, setLoading] = useState(false)

    const getPosts = async () => {

        setLoading(true)

        await fetch("https://dev.to/api/articles?username=norbertok")
            .then(res => res.json())
            .then(data => setPost(data))

        setLoading(false)
    }

    useEffect(() => {

        getPosts()


    }, [])

    if (loading) {
        return (
            <>
                <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 mb-4"></div>
                    <h2 className="text-center text-indigo-600 text-xl font-semibold">Cargando...</h2>
                </div>
            </>
        )
    }



    return (
        <>
            <div className="max-w-3xl mx-auto">
                <div className="mx-auto container pt-12 pb-10 px-4 sm:px-4">
                    <h1 className="mb-2 mt-2 text-2xl font-bold">{lang.blog.h1}</h1>
                    <main>

                        {
                            post.map((entry, index) =>
                                <Entradas
                                    lang={lang}
                                    key={index}
                                    img={entry.cover_image}
                                    title={entry.title}
                                    descrip={entry.description}
                                    tags={entry.tags}
                                    urlArticulo={entry.url}
                                    nlikes={entry.positive_reactions_count}
                                    nComments={entry.comments_count}
                                    fecha={entry.published_at}
                                />
                            )
                        }

                    </main>
                </div>
            </div>
        </>
    )
}
