import React from 'react'

export const IconContact = ({ colorActive, colorHover, icon, url }) => {
    return (
        <a className={`flex flex-col ${colorActive} hover:${colorHover} shadow-md focus:outline-none rounded-lg text-white text-xs self-center m-2 p-2`}
            target="_blank"
            href={url}
            rel="noreferrer"
        >
            <i className={`${icon} text-4xl fill-current text-white`} aria-hidden="true"></i>
        </a>
    )
}
