import React from 'react'
import dayjs from 'dayjs'

export const Entradas = ({ img, title, descrip, tags, urlArticulo, lang, nlikes, nComments, fecha }) => {
    return (
        <>
            <div className="max-w-sm w-full max-w-full flex flex-col mb-8 shadow-lg">
                <div
                    className="h-64 flex-none rounded-t-lg text-center overflow-hidden flex flex-col border border-b-0 border-gray-400">
                    <div className="flex-grow gatsby-image-wrapper relative overflow-hidden">

                        {/* <div class="bg-opacity-25"></div> */}
                        <img className=""
                            src={img} style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top', opacity: '1', transition: 'opacity 500ms ease 0s' }}
                            alt={title}
                        />
                        {/* style={{display: 'none'}} */}
                    </div>
                </div>
                <div
                    className="border border-t-0 border-gray-400 bg-white rounded-b rounded-b-none px-4 py-8 flex flex-col justify-between leading-normal">
                    <h2 className="font-bold m-0 mb-4">{title}</h2>
                    <p>{descrip}</p>

                    <p className="mt-2 text-indigo-600 font-semibold">
                        <a href={urlArticulo} target="_blank" rel="noopener noreferrer">
                            {lang.blog.pLinkBlog}
                        </a>
                    </p>
                    {/* Icono git */}


                    <section className="flex">
                        <div className="max-w-screen-lg">
                            <div className="flex flex-wrap">

                                <div className="flex flex-col text-xs self-center m-2 p-2">
                                    <i className="far fa-heart text-4xl fill-current text-red-500" aria-hidden="true"></i>
                                    <center><span class="fa-layers-counter text-red-500">{nlikes}</span></center>
                                </div>

                                <div className="flex flex-col text-xs self-center m-2 p-2">
                                    <i className="far fa-comment-dots text-4xl fill-current text-indigo-600" aria-hidden="true"></i>
                                    <center><span class="fa-layers-counter text-indigo-600">{nComments}</span></center>
                                </div>

                                <div className="flex flex-col text-xs self-center m-2 p-2">
                                    <i className="far fa-calendar text-4xl fill-current text-green-600" aria-hidden="true"></i>
                                    <center><span class="fa-layers-counter text-green-600">{dayjs(fecha).format('MM/YY')}</span></center>
                                </div>

                            </div>
                        </div>
                    </section>

                    <p className="bg-gradient-to-r from-blue-600 to-blue-400 opacity-80 text-white o p-2 rounded-lg mt-1 w-content ">{tags}</p>

                </div>
            </div>
        </>
    )
}
