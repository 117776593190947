import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Work = ({ img, title, descrip1, urlNpm, tagsTec, urlProyecto, urlRepo, lang }) => {
    return (
        <>
            <div className="max-w-sm w-full max-w-full flex flex-col mb-8 shadow-lg">
                <div
                    className="h-64 flex-none rounded-t-lg text-center overflow-hidden flex flex-col border border-b-0 border-gray-400">
                    <div className="flex-grow gatsby-image-wrapper relative overflow-hidden">
                        <LazyLoadImage
                            alt={title}
                            effect="blur"
                            src={img}
                        />
                    </div>
                </div>
                <div
                    className="border border-t-0 border-gray-400 bg-white rounded-b rounded-b-none px-4 py-8 flex flex-col justify-between leading-normal">
                    <h2 className="font-bold m-0 mb-4">{title}</h2>
                    <p>{descrip1}</p>
                    {/* {
                        descrip2 &&
                        <p>{descrip2}</p>
                    } */}
                    <p className="mt-2 text-indigo-600 font-semibold">
                        <a href={urlProyecto} target="_blank" rel="noopener noreferrer">
                            {lang.textJobs.pVisitProyect}
                        </a>
                    </p>

                    <div className='flex'>
                        {/* Icono git */}
                        {
                            urlRepo &&
                            <section className="flex">
                                <div className="max-w-screen-lg">
                                    <div className="flex flex-wrap">

                                        <a className="flex flex-col bg-black hover:bg-gray-800 shadow-md focus:outline-none rounded-lg text-white text-xs self-center m-2 p-2"
                                            target="_blank" href={urlRepo} rel="noreferrer">
                                            <i className="fab fa-github text-4xl fill-current text-white" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        }
                        {/*icono NPM */}
                        {
                            urlNpm &&
                            <section className="flex">
                                <div className="max-w-screen-lg">
                                    <div className="flex flex-wrap">

                                        <a className="flex flex-col bg-black hover:bg-gray-800 shadow-md focus:outline-none rounded-lg text-white text-xs self-center m-2 p-2"
                                            target="_blank" href={urlNpm} rel="noreferrer">
                                            <i className="fab fa-npm text-4xl fill-current text-white" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        }
                    </div>

                    <p className="bg-gradient-to-r from-blue-600 to-blue-400 opacity-80 text-white o p-2 rounded-lg mt-1 w-content ">{tagsTec}</p>

                </div>
            </div>
        </>
    )
}
