import React from 'react'
import { Link } from 'react-router-dom'


export const Navbar = ({ changeLang, lang }) => {



    /* const changeLang = () => {
        console.log('Cambiar lenguaje')
    } */

    return (
        <>
            <header className="mx-auto container px-6 py-4">
                <div className="flex items-center h-10 flex-col md:flex-row">
                    <div className="flex-grow">
                        <Link aria-current="page" className="flex items-center text-black font-bold text-2xl mb-2 md:mb-0" to="/">
                            Norberto Krucheski
                </Link>
                    </div>
                    <div className="font-semibold text-gray-600">
                        <Link className="pr-4 uppercase" to="/">
                            <i className="fa fa-user text-xl fill-current text-black m-1" aria-hidden="true"></i>
                        </Link>
                        <Link className="pr-4 uppercase" to="/jobs">
                            <i className="fa fa-briefcase text-xl fill-current text-black m-1" aria-hidden="true"></i>
                        </Link>
                        <Link className="pr-4 uppercase" alt="blog" to="/blog">
                            <i className="fa fa-newspaper text-xl fill-current text-black m-1" aria-hidden="true"></i>
                        </Link>
                        <Link to="#" className="pr-4 uppercase text-indigo-600" onClick={changeLang}>'{lang.locale}'</Link>
                        <a className="pr-4 uppercase" href="https://www.linkedin.com/in/norberto-krucheski/" target="_blank" rel="noreferrer">
                            <i className="fa fa-linkedin text-xl fill-current text-black m-1" aria-hidden="true"></i>
                        </a>
                        <a className="pr-4 uppercase" href="https://api.whatsapp.com/send?phone=541149733491&text=Hola%20Norberto,%20te%20contacto%20desde%20https://norbertok.com" target="_blank" rel="noreferrer">
                            <i className="fa fa-whatsapp text-xl fill-current text-black m-1" aria-hidden="true"></i>
                        </a>
                        <a className="pr-4 uppercase" href="https://github.com/NorbertOSK" target="_blank" rel="noreferrer">
                            <i className="fab fa-github text-xl fill-current text-black m-1" aria-hidden="true"></i>
                        </a>
                        <a className="pr-4 uppercase" href="https://www.npmjs.com/~norbertok" target="_blank" rel="noreferrer">
                            <i className="fab fa-npm text-xl fill-current text-black m-1" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </header>
        </>
    )
}

