import React from 'react'

import { IconContact } from '../icons/IconContact'

import iconsContact from '../icons/IconsContact.json'

export const Contact = ({ lang }) => {
    return (
        <> 
            <section className="flex item-center justify-center lg:-mt-10">
                <div className="max-w-screen-sm">
                    <p className="mb-1 text-center">{lang.textHome.pContacto}</p>
                    <div className="flex flex-wrap items-center justify-center">


                        {
                            iconsContact.map((icon, index) => <IconContact key={index} colorActive={icon.colorActive} colorHover={icon.colorHover} icon={icon.icon} url={icon.url} />)
                        }

                    </div>
                </div>
            </section>
        </>
    )
}
