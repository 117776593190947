import React from 'react'
import fotoNK from '../../img/fotonk.png'
import { Contact } from '../contact/Contact'
import { Icon } from '../icons/Icon'

//import icons from '../icons/icons.json'
import iconsT from '../icons/icons.json'

export const HomeScreen = ({ lang }) => {

    //console.log(lang)
    //console.log(icons)

    return (
        <>
            <section className="mx-auto container pt-12 px-4 sm:px-0 lg:-mt-10">
                <main>
                    <div className="max-w-2xl mx-auto">
                        <div>
                            <div className="w-48 mx-auto border-2 border-indigo-600 rounded-full round mb-8 gatsby-image-wrapper relative overflow-hidden">

                                <img src={fotoNK} alt="Norberto Krucheski" />
                            </div>
                            <h1 className="font-semibold">{lang.textHome.h1}</h1>
                            <p className="mb-1">{lang.textHome.p1}</p>
                            <p className="mb-1">{lang.textHome.p2}</p>
                            <p className="mb-1">{lang.textHome.p3}</p>
                            <p className="mb-1">{lang.textHome.p4}</p>
                        </div>
                    </div>
                </main>
            </section>

            {/* icons */}

            <section className="flex item-center justify-center p-4 sm:p-12 -mt-5">
                <div className="max-w-screen-sm">
                    <div className="flex flex-wrap items-center justify-center">


                        {
                            iconsT.map((icon, index) => <Icon key={index} colorActive={icon.colorActive} colorHover={icon.colorHover} icon={icon.icon} name={icon.name} />)
                        }



                    </div>
                </div>
            </section>

            <Contact lang={lang} />


        </>
    )
}
